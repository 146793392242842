
import apiFetch from "@/utils/request";
import { AxiosResponse } from "axios";
import { Vue, Options } from "vue-class-component";
import { ApiEndpoint } from "../../types/index";

@Options({
  // Define component options here
  // props: {
  //   apiEndpoint: {
  //     type: Object,
  //   },
  // },
})
class ApiEndpointComponent extends Vue {
  apiEndpoints: ApiEndpoint[] = [];
  loading: boolean = true;
  error: string = "";
  created() {
    this.getApiEndpoints().then(() => {
      this.loading = false;
    });
  }
  async getApiEndpoints() {
    try {
      const response: AxiosResponse<{ data: ApiEndpoint[] }> =
        await apiFetch.apiEndpoint.getApiEndpointsList();
      this.apiEndpoints = response?.data?.data;
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
  async deleteApiEndpoint(id: number) {
    try {
      await apiFetch.apiEndpoint.deleteApiEndpoint(id);
      await this.getApiEndpoints();
    } catch (err) {
      this.error = err.response.data.error.message;
    }
  }
}
export default ApiEndpointComponent;
